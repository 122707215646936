import {Point} from "@pixi/math";
import {WorldConfig} from "../config/WorldConfig";
import {Rectangle} from "pixi.js";

export class MathUtility {
    static getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static getMatrix(width: number, height: number) {
        const arr = new Array(width + 1).fill(0);
        const matrix = new Array(height + 1).fill(arr);
        return this.clearMatrix(matrix);
    }

    static clearMatrix(matrix: number[][]) {
        return matrix.map(arr => arr.map(el => 0));
    }

    static getCellFromPoint(point: Point, offset = 0) {
        return new Point(Math.floor((point.x - offset) / WorldConfig.cellSize), Math.floor((point.y - offset) / WorldConfig.cellSize));
    }

    //if r1||r2||r3 == 0 point on edge
    static isPointInTriangle(point: Point, t1: Point, t2: Point, t3: Point) {
        const r1 = (t1.x - point.x) * (t2.y - t1.y) - (t2.x - t1.x) * (t1.y - point.y);
        const r2 = (t2.x - point.x) * (t3.y - t2.y) - (t3.x - t2.x) * (t2.y - point.y);
        const r3 = (t3.x - point.x) * (t1.y - t3.y) - (t1.x - t3.x) * (t3.y - point.y);

        if (r1 == 0 || r2 == 0 || r3 == 0) {
            return true;
        }

        return (Math.sign(r1) === Math.sign(r2)) && (Math.sign(r1) === Math.sign(r3));
    }

    static pointInSquare(point: Point, square: Rectangle): boolean {
        point = new Point(Math.ceil(point.x), Math.ceil(point.y));

        return point.x >= square.x && point.x <= square.x + square.width
            && point.y >= square.y && point.y <= square.y + square.height
    }

    static findClosestEmptyCell(matrix: number[][], startX: number, startY: number) {
        const queue = [{x: startX, y: startY}];
        const visited = new Set();

        while (queue.length > 0) {
            const {x, y} = queue.shift();

            if (matrix[y][x] === 0) {
                return new Point(x, y);
            }

            const neighbors = [
                {x: x + 1, y},
                {x: x - 1, y},
                {x, y: y + 1},
                {x, y: y - 1}
            ];

            for (const neighbor of neighbors) {
                if (neighbor.x >= 0 && neighbor.x < matrix[0].length &&
                    neighbor.y >= 0 && neighbor.y < matrix.length &&
                    !visited.has(`${neighbor.x},${neighbor.y}`)) {
                    queue.push(neighbor);
                    visited.add(`${neighbor.x},${neighbor.y}`);
                }
            }
        }

        return null;
    }

    static getPointFromCell(cell: Point) {
        return cell.multiplyScalar(40);
    }
}
