import '@pixi/math-extras';
import {Point} from '@pixi/math';
import {AnimatedSprite, Graphics, Rectangle, Texture, Ticker} from "pixi.js";
import {Container as Di, Service} from "typedi";
import {IAnimate} from "./interface/IAnimate";
import {Character} from "./Character";
import {Light} from "./Light";
import {World} from "./World";
import {Wall} from "./Wall";
import {MathUtility} from "./helper/MathUtility";
import {WorldConfig} from "./config/WorldConfig";
import {sound} from "@pixi/sound";
import {EndPathPoint} from "./EndPathPoint";
import {Game} from "./Game";

@Service()
export class Player extends Character implements IAnimate {
    player = new Graphics();
    shadow = new Graphics();
    health = new Graphics();

    healthPoints = 100;
    takeDamageTime = 20;
    takeDamageTimeCounter = 20;

    gridSize = 21;
    halfGridSize = Math.ceil(this.gridSize / 2);
    startPos = new Point(Math.floor(this.gridSize / 2), Math.floor(this.gridSize / 2));

    speed = 2;
    light: Light;

    coins: number = 0;

    animations = {
        idle: {
            up: "idle-down-",
            left: "idle-left-",
            down: "idle-top-",
            right: "idle-leftTop-",
            leftTop: "idle-rightTop-",
        },
        walk: {
            down: "walk-down-",
            up: "walk-up-",
            left: "walk-left-down-",
            right: "walk-left-down-",
        }
    }

    currentAnimation = this.animations.idle.down;

    dirAnimWalk: any = {
        ["-1,0"]: this.animations.walk.left,
        ["1,0"]: this.animations.walk.left,
        ["0,1"]: this.animations.walk.down,
        ["0,-1"]: this.animations.walk.up,
    };

    dirAnimIdle: any = {
        ["-1,0"]: this.animations.idle.left,
        ["1,0"]: this.animations.idle.right,
        ["0,1"]: this.animations.idle.down,
        ["0,-1"]: this.animations.idle.up,
    };

    anim: AnimatedSprite;

    endPathPoint = new EndPathPoint();

    constructor() {
        super();
        this.container.addChild(this.grid);
        this.container.addChild(this.shadow);
        this.container.addChild(this.player);
        this.container.addChild(this.health);

        this.container.addChild(this.debug);

        this.container.pivot.x = -WorldConfig.halfCellSize;
        this.container.pivot.y = -WorldConfig.halfCellSize;

        this.container.interactive = true;
        this.container.cursor = "pointer";
        this.container.eventMode = "static";
        this.container.hitArea = new Rectangle(-this.gridSize * 40 / 2, -this.gridSize * 40 / 2, this.gridSize * 40, this.gridSize * 40);


        this.eventEmitter.on("end-movement", (p) => {
            this.currentAnimation = this.dirAnimIdle[`${this.direction.x},${this.direction.y}`];
            const frames = [];
            for (let i = 0; i < 8; i++) {
                frames.push(Texture.from(`${this.currentAnimation}${i}.png`));
            }
            this.anim.textures = frames;
            this.anim.play();
            this.direction = null;

            Di.get(World).drawWalls();
            this.movement = false;
            if (this.callback) {
                this.callback();
                this.callback = undefined;
            }
            this.detectObstacle();
        });

        this.eventEmitter.on("take-damage", () => {
            if (this.healthPoints <= 0) {
                Di.get(Game).doGameOver();
                return;
            }
            this.healthPoints -= 10;
            this.drawHealth();
            this.drawDamage();
            sound.play("playerHit", {loop: false});
        });
    }

    move() {
        this.detectObstacle();
        this.calculatePath();
        // Di.get(World).drawWalls();
    }

    async init() {
        await super.init();
        await this.endPathPoint.init();
        Di.get(World).container.addChild(this.endPathPoint.container);

        this.light = new Light(new Point(0, 0));
        await this.light.init();
        this.light.light.scale = 0.8;
        this.light.setPosition(this.container.position);

        this.eventEmitter.on("change-position", (pos) => {
            Di.get(World).drawWalls();
            this.drawPath();
        });

        this.eventEmitter.on("end-movement", (pos) => {
            Di.get(World).drawWalls();
            sound.stop("footsteps");
            this.endPathPoint.clear();
        });

        this.debug.tint = "#4bc74b";

        const frames = [];
        for (let i = 0; i < 8; i++) {
            frames.push(Texture.from(`${this.currentAnimation}${i}.png`));
        }
        this.anim = new AnimatedSprite(frames);
        this.container.addChild(this.anim);

        this.anim.scale = 2;
        this.anim.anchor.set(0.5);
        this.anim.animationSpeed = 0.1 * this.speed;
        this.anim.play();

        this.container.on("pointerdown", (e) => {
            this.pointOfInterest = this.container.toLocal(e.global);
            this.endPathPoint.play(e.global);
            this.movement = false;
            this.endPathPoint.draw();
            this.move();
        });

        this.eventEmitter.on("have-move-path", () => {
            if (!this.path || this.path.length == 0) {
                if (sound.find("footsteps").isPlaying) {
                    sound.stop("footsteps");
                }
                return;
            }

            this.drawPath();
            this.calculateDirection(this.path[0]);

            if (sound.find("footsteps").isPlaying) {
                return;
            }
            sound.play("footsteps", {loop: true, speed: 1.1, volume: 0.5});
        });

    }

    async addCoin() {
        this.coins++;
        Di.get(Game).ui.setText();
        const allCoins = Di.get(World).coins.length;
        if (this.coins == allCoins) {
            await Di.get(Game).nextLevel();
        }
    }

    isVisible(wall: Wall) {
        const w: Point = this.container.toLocal(wall.container.getGlobalPosition());
        const rect = new Rectangle(w.x, w.y, 40, 40);

        return this.light?.visibility.some((arr, index) => {
            const [p1, p2] = arr;

            const t1 = MathUtility.pointInSquare(p1, rect);
            const t2 = MathUtility.pointInSquare(p2, rect);

            return t1 || t2;
        });
    }

    clear() {
        this.grid.clear();
        // this.shadowPath.clear();
    }

    draw() {
        this.drawHealth();
    }

    resize() {

    }

    drawPlayer(cell: Point) {
        this.movement = false;
        this.container.position = MathUtility.getPointFromCell(cell);
        Di.get(Player).light.setPosition(this.container.position);
        Di.get(World).drawWalls();
    }

    drawHealth() {
        this.health.clear();
        this.health.rect(-30, -35, 60, 8);
        this.health.stroke({width: 1, color: "#6c6c6c"});

        const p = this.healthPoints / 100;
        const full = 60;

        this.health.rect(full / 2 * -1, -35, full * p, 8);
        this.health.fill("#dc1f1f");
    }

    drawDamage() {
        this.anim.tint = "#c72b2b";
    }

    updateAnimations() {
        this.currentAnimation = this.dirAnimWalk[`${this.direction.x},${this.direction.y}`];
        const frames = [];
        for (let i = 0; i < 8; i++) {
            frames.push(Texture.from(`${this.currentAnimation}${i}.png`));
        }

        if (this.direction.x > 0) {
            this.anim.scale.x = -2;
        }

        if (this.direction.x < 0) {
            this.anim.scale.x = 2;
        }

        this.anim.textures = frames;
        this.anim.play();
    }

    update(time: Ticker) {
        super.update(time);

        if (this.movement) {
            this.light.setPosition(this.container.position);
            this.endPathPoint.update(time);
        }

        this.takeDamageTimeCounter++;
        if (this.takeDamageTime < this.takeDamageTimeCounter) {
            this.takeDamageTimeCounter = 0;
            this.anim.tint = "#fff";
        }
    }
}
