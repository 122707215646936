import {EndPoint} from "./EndPoint";
import {Point} from "@pixi/math";
import {Segment} from "./Segment";
import {Rectangle} from "pixi.js";

type CB = (item: Segment | Rectangle) => Segment[] | EndPoint[];
type ClassConstructor<T> = new (...rec: any) => T;

export class RayHelper {
    static endpointCompare(pointA: EndPoint, pointB: EndPoint) {
        if (pointA.angle > pointB.angle) return 1;
        if (pointA.angle < pointB.angle) return -1;
        if (!pointA.beginsSegment && pointB.beginsSegment) return 1;
        if (pointA.beginsSegment && !pointB.beginsSegment) return -1;
        return 0;
    };

    static lineIntersection(point1: Point, point2: Point, point3: Point, point4: Point) {
        const s = (
            (point4.x - point3.x) * (point1.y - point3.y) -
            (point4.y - point3.y) * (point1.x - point3.x)
        ) / (
            (point4.y - point3.y) * (point2.x - point1.x) -
            (point4.x - point3.x) * (point2.y - point1.y)
        );

        return new Point(
            point1.x + s * (point2.x - point1.x),
            point1.y + s * (point2.y - point1.y)
        );
    };

    static leftOf(segment: Segment, point: Point) {
        const cross = (segment.p2.x - segment.p1.x) * (point.y - segment.p1.y)
            - (segment.p2.y - segment.p1.y) * (point.x - segment.p1.x);
        return cross < 0;
    };

    static interpolate(pointA: Point, pointB: Point, f: number) {
        return new Point(
            pointA.x * (1 - f) + pointB.x * f,
            pointA.y * (1 - f) + pointB.y * f
        );
    };

    static segmentInFrontOf(segmentA: Segment, segmentB: Segment, relativePoint: Point) {
        const A1 = RayHelper.leftOf(segmentA, RayHelper.interpolate(segmentB.p1, segmentB.p2, 0.01));
        const A2 = RayHelper.leftOf(segmentA, RayHelper.interpolate(segmentB.p2, segmentB.p1, 0.01));
        const A3 = RayHelper.leftOf(segmentA, relativePoint);
        const B1 = RayHelper.leftOf(segmentB, RayHelper.interpolate(segmentA.p1, segmentA.p2, 0.01));
        const B2 = RayHelper.leftOf(segmentB, RayHelper.interpolate(segmentA.p2, segmentA.p1, 0.01));
        const B3 = RayHelper.leftOf(segmentB, relativePoint);

        if (B1 === B2 && B2 !== B3) return true;
        if (A1 === A2 && A2 === A3) return true;
        if (A1 === A2 && A2 !== A3) return false;
        if (B1 === B2 && B2 === B3) return false;

        return false;
    };

    static flatMap(cb: CB, array: Rectangle[] | Segment[]): Segment[] | EndPoint[] {
        //@ts-ignore
        return array.reduce((flatArray, item) => flatArray.concat(cb(item)), []);
    }

    static getCorners(rect: Rectangle) {
        return {
            nw: [rect.x, rect.y],
            sw: [rect.x, rect.y + rect.height],
            ne: [rect.x + rect.width, rect.y],
            se: [rect.x + rect.width, rect.y + rect.height]
        }
    };

    static segmentsFromCorners({nw, sw, ne, se}: { nw: number[], sw: number[], ne: number[], se: number[] }) {
        return [
            new Segment(nw[0], nw[1], ne[0], ne[1]),
            new Segment(nw[0], nw[1], sw[0], sw[1]),
            new Segment(ne[0], ne[1], se[0], se[1]),
            new Segment(sw[0], sw[1], se[0], se[1])
        ];
    };

    static rectangleToSegments(rectangle: Rectangle) {
        return RayHelper.segmentsFromCorners(RayHelper.getCorners(rectangle));
    }

    static calculateEndPointAngles(lightSource: Point, segment: Segment) {
        const {x, y} = lightSource;
        const dx = 0.5 * (segment.p1.x + segment.p2.x) - x;
        const dy = 0.5 * (segment.p1.y + segment.p2.y) - y;

        segment.d = (dx * dx) + (dy * dy);
        segment.p1.angle = Math.atan2(segment.p1.y - y, segment.p1.x - x);
        segment.p2.angle = Math.atan2(segment.p2.y - y, segment.p2.x - x);
    };

    static setSegmentBeginning(segment: Segment) {
        let dAngle = segment.p2.angle - segment.p1.angle;

        if (dAngle <= -Math.PI) dAngle += 2 * Math.PI;
        if (dAngle > Math.PI) dAngle -= 2 * Math.PI;

        segment.p1.beginsSegment = dAngle > 0;
        segment.p2.beginsSegment = !segment.p1.beginsSegment;
    };

    static processSegments(lightSource: Point, segments: Segment[]) {
        for (let i = 0; i < segments.length; i += 1) {
            let segment = segments[i];
            RayHelper.calculateEndPointAngles(lightSource, segment);
            RayHelper.setSegmentBeginning(segment);
        }

        return segments;
    };

    static getSegmentEndPoints(segment: Segment) {
        return [segment.p1, segment.p2]
    };

    static loadMap(room: Rectangle, blocks: Rectangle[], walls: Segment[], lightSource: Point): EndPoint[] {
        const seg = RayHelper.flatMap(RayHelper.rectangleToSegments, blocks) as Segment[];

        const segments = RayHelper.processSegments(lightSource, [
            ...RayHelper.rectangleToSegments(room),
            ...seg,
            ...walls
        ]);

        return RayHelper.flatMap(RayHelper.getSegmentEndPoints, segments) as EndPoint[];
    };

    static getTrianglePoints(origin: Point, angle1: number, angle2: number, segment: Segment): Point[] {
        const p1 = origin;
        const p2 = new Point(origin.x + Math.cos(angle1), origin.y + Math.sin(angle1));
        const p3 = new Point(0, 0);
        const p4 = new Point(0, 0);

        if (segment) {
            p3.x = segment.p1.x;
            p3.y = segment.p1.y;
            p4.x = segment.p2.x;
            p4.y = segment.p2.y;
        } else {
            p3.x = origin.x + Math.cos(angle1) * 200;
            p3.y = origin.y + Math.sin(angle1) * 200;
            p4.x = origin.x + Math.cos(angle2) * 200;
            p4.y = origin.y + Math.sin(angle2) * 200;
        }

        const pBegin = RayHelper.lineIntersection(p3, p4, p1, p2);

        p2.x = origin.x + Math.cos(angle2);
        p2.y = origin.y + Math.sin(angle2);

        const pEnd = RayHelper.lineIntersection(p3, p4, p1, p2);

        if (pBegin.x == pEnd.x && pBegin.y == pEnd.y) {
            return null;
        }

        return [pBegin, pEnd];
    };

    static calculateVisibility(origin: Point, endpoints: EndPoint[]): Point[][] {
        let openSegments = [];
        let output: Point[][] = [];
        let beginAngle = 0;

        endpoints.sort(RayHelper.endpointCompare);

        for (let pass = 0; pass < 2; pass += 1) {
            for (let i = 0; i < endpoints.length; i += 1) {
                let endpoint = endpoints[i];
                let openSegment = openSegments[0];

                if (endpoint.beginsSegment) {
                    let index = 0
                    let segment = openSegments[index];
                    while (segment && RayHelper.segmentInFrontOf(endpoint.segment, segment, origin)) {
                        index += 1;
                        segment = openSegments[index]
                    }

                    if (!segment) {
                        openSegments.push(endpoint.segment);
                    } else {
                        openSegments.splice(index, 0, endpoint.segment);
                    }
                } else {
                    let index = openSegments.indexOf(endpoint.segment)
                    if (index > -1) openSegments.splice(index, 1);
                }

                if (openSegment !== openSegments[0]) {
                    if (pass === 1) {
                        let trianglePoints = RayHelper.getTrianglePoints(origin, beginAngle, endpoint.angle, openSegment);
                        if (trianglePoints)
                            output.push(trianglePoints);
                    }
                    beginAngle = endpoint.angle;
                }
            }
        }

        return output;
    };

    static spreadMap<T>(cb: ClassConstructor<T>) {
        return (array2d: number[][]) =>
            array2d.map((array1d: number[]) => {
                //@ts-ignore
                return new cb(...array1d);
            });
    }
}
