import {EndPoint} from "./EndPoint";

export class Segment {
    p1: EndPoint;
    p2: EndPoint;
    d: number;

    constructor(x1: number, y1: number, x2: number, y2: number) {
        this.p1 = new EndPoint(x1, y1);
        this.p2 = new EndPoint(x2, y2);
        const segment = {p1: this.p1, p2: this.p2, d: 0};

        this.p1.segment = segment;
        this.p2.segment = segment;
    }
}
