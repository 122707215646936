import {Point} from "@pixi/math";
import {Container as Di} from "typedi";
import {Assets, Container, Graphics, Rectangle, Sprite, Ticker} from "pixi.js";
import {RayHelper} from "./helper/RayHelper";
import {Segment} from "./helper/Segment";
import {World} from "./World";
import {IAnimate} from "./interface/IAnimate";
import {Enemy} from "./Enemy";
import {WorldConfig} from "./config/WorldConfig";

export class Light implements IAnimate {
    id = Math.random().toString(16).slice(2);
    light: Sprite;
    container = new Container();

    lightSource: Point;
    visibility: Point[][] = [];
    room: Rectangle;

    makeSegments = RayHelper.spreadMap<Segment>(Segment)
    makeBlocks = RayHelper.spreadMap<Rectangle>(Rectangle);
    gr = new Graphics();
    debug = new Graphics();

    blocks: Rectangle[] = [];

    shadow = new Graphics();

    parent: Enemy;

    constructor(pos: Point) {
        this.container.position = pos;
    }

    async init() {
        const texture = await Assets.load("./assets/light.png");
        this.light = Sprite.from(texture);
        this.light.tint = "#fff";
        this.light.anchor.set(0.5);
        this.light.scale = 0.5;
        this.light.alpha = 0.6;
        this.container.addChild(this.gr);
        this.container.addChild(this.light);
        this.container.addChild(this.shadow);
        this.container.addChild(this.debug);
        this.container.mask = this.shadow;

        Di.get(World).addLight(this);

        // this.light.filters = [new BlurFilter(30)];

        this.lightSource = new Point(0, 0);

        this.loadBlocks();
        this.lightCalculate();
    }

    setParent(enemy: Enemy) {
        this.parent = enemy;
    }

    drawDebug(x: number, y: number) {
        // this.debug.clear();
        this.debug.circle(x, y, 5);
        this.debug.fill("#cee");
    }

    setPosition(pos: Point) {
        this.container.position = new Point(pos.x+20,pos.y+20);
        this.loadBlocks();
        this.lightCalculate();
    }

    loadBlocks() {
        this.debug.clear();
        this.getRoom();

        const walls = Di.get(World).walls.map(w => {
            const p = this.container.toLocal(w.container.getGlobalPosition());
            return new Rectangle(p.x, p.y, w.lightBounds, w.lightBounds);
        });
        const enemies = Di.get(World).enemies.map(e => {
            if (e == this.parent) {
                return null;
            }
            const p = this.container.toLocal(e.container.getGlobalPosition());
            return new Rectangle(p.x + WorldConfig.lightOffset, p.y + WorldConfig.lightOffset, e.lightBounds, e.lightBounds);
        }).filter(e => e != null);

        const combineObstacles: Rectangle[] = [...walls, ...enemies];

        this.blocks = this.makeBlocks(combineObstacles.map((wall) => {
            if (this.room.x > wall.x || this.room.x + this.room.width < wall.x) {
                return null;
            }
            if (this.room.y > wall.y || this.room.y + this.room.height < wall.y) {
                return null;
            }
            // this.drawDebug(wall.x, wall.y);
            return [~~wall.x, ~~wall.y, ~~wall.width, ~~wall.height]
        }).filter(e => e != null));
    }

    getRoom() {
        this.room = new Rectangle(
            ~~(this.lightSource.x - this.light.width / 2),
            ~~(this.lightSource.y - this.light.height / 2),
            this.light.width,
            this.light.height
        );
    }

    lightCalculate() {
        // const plPos = new Point().copyFrom(Di.get(Player).container.position);

        const endpoints = RayHelper.loadMap(this.room, this.blocks, [], this.lightSource);
        this.visibility = RayHelper.calculateVisibility(this.lightSource, endpoints);
        this.drawLight();
    }

    drawLight() {
        this.gr.clear();
        // this.gr.rect(this.room.x, this.room.y, this.room.width, this.room.height);
        // this.gr.stroke({width: 1, color: "#ccc"});
        // this.blocks.forEach((block) => {
        //     this.gr.rect(block.x, block.y, block.width, block.height);
        //     this.gr.stroke({width: 1, color: "#dfe8a6"});
        // });

        this.drawShadow();
    }

    drawShadow() {
        this.shadow.clear();
        const plPos = this.lightSource;

        // this.shadow.circle(0,0,600);
        if (this.visibility.length) {
            this.visibility.forEach((arr, index) => {
                let [p1, p2] = arr;
                //TODO Math.ceil
                this.shadow.moveTo(plPos.x, plPos.y);
                this.shadow.lineTo(p1.x, p1.y);
                this.shadow.lineTo(p2.x, p2.y);
                this.shadow.lineTo(plPos.x, plPos.y);
            });
            this.shadow.stroke({width: 1, color: "#aec236"});
            this.shadow.fill("#fff");
        }
    }

    update(time: Ticker): void {
        this.loadBlocks();
        this.lightCalculate();
    }
}
