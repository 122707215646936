import {Container, Graphics, Ticker} from "pixi.js";
import {Point} from "@pixi/math";
import {IAnimate} from "./interface/IAnimate";
import {WorldConfig} from "./config/WorldConfig";

export class Wall implements IAnimate {
    id = Math.random().toString(16).slice(2);
    container = new Container();
    block = new Graphics();
    slide: number = 0;
    visible = false;
    timeout: any;

    lightBounds = WorldConfig.cellSize;

    constructor(point: Point) {
        this.container.addChild(this.block);
        this.container.position = point;
        this.block.alpha = 0;
    }

    private drawBlock() {
        this.block.clear();
        this.block.rect(0, 0, WorldConfig.cellSize, WorldConfig.cellSize);
        this.block.fill("#a8a328");
    }

    draw() {
        this.clear();
        this.drawBlock();
    }

    clear() {
        this.block.clear();
    }

    setVisible() {
        this.visible = true;
        // clearTimeout(this.timeout);
        // this.timeout = setTimeout(() => {
        //     // this.visible = false;
        // }, 5000);
    }

    resize() {
        this.container.pivot.x = this.container.width / 2;
        this.container.pivot.y = this.container.height / 2;
    }

    hide() {
        if (this.block.alpha == 0) {
            return;
        }
        this.slide = ((this.slide + 1) % 100);
        this.block.alpha = 1 - (this.slide + 1) / 100;
    }

    show() {
        if (this.block.alpha == 1) {
            return;
        }
        this.slide = (this.slide + 1) % 100;
        this.block.alpha = (this.slide + 1) / 100 * 20;
    }

    update(time: Ticker): void {
        if (this.visible) {
            this.show();
        }

        if (!this.visible) {
            this.hide();
        }
    }
}
