import {IAnimate} from "./interface/IAnimate";
import {Ticker} from "pixi.js";
import {Enemy} from "./Enemy";
import {MathUtility} from "./helper/MathUtility";

export abstract class AI implements IAnimate {
    tikCounter = 0;
    time = MathUtility.getRandomInt(60, 120);
    parent: Enemy;

    setParent(e: Enemy) {
        this.parent = e;
    }

    abstract run(): void;

    update(time: Ticker): void {
        this.tikCounter++;
        if (this.time <= this.tikCounter) {
            this.run();
            this.tikCounter = 0;
            this.time = MathUtility.getRandomInt(60, 120);
        }
    }
}
