import {Container} from "pixi.js";

export abstract class BaseEntity {
    wrapper = document.getElementById('wrapper');
    wrRect = this.wrapper.getBoundingClientRect();

    container = new Container();

    async init() {
        await this.reload();
        addEventListener("resize", async () => {
            await this.reload();
        });
    }

    abstract clear(): void;

    abstract draw(): void;

    abstract resize(): void;

    updateWrapperSize() {
        this.wrRect = this.wrapper.getBoundingClientRect();
    }

    async reload() {
        this.clear();
        this.updateWrapperSize();
        await this.draw();
        this.resize();
    }
}
