import {AI} from "./AI";
import {Ticker} from "pixi.js";
import {MathUtility} from "./helper/MathUtility";
import {Point} from "@pixi/math";

export class Hunting extends AI {

    counter = 0;

    update(time: Ticker) {
        super.update(time);
    }

    run() {
        const pos = this.parent.getPlayerLocalCell();
        this.parent.detectObstacle();
        if (!pos) {
            this.parent.move(new Point(MathUtility.getRandomInt(0, 10), MathUtility.getRandomInt(0, 10)));
            return;
        }
        const emptyCell = MathUtility.findClosestEmptyCell(this.parent.matrix, pos.x, pos.y);

        this.parent.calculateLanToPlayer();

        if (this.parent.lenToPlayer <= 1) {
            return;
        }
        this.parent.move(emptyCell);
    }
}
