import {Container as Di} from "typedi";
import {BaseEntity} from "./BaseEntity";
import {AnimatedSprite, Graphics, Texture, Ticker} from "pixi.js";
import {Point} from "@pixi/math";
import {WorldConfig} from "./config/WorldConfig";
import {World} from "./World";
import {MathUtility} from "./helper/MathUtility";
import {IAnimate} from "./interface/IAnimate";

export class EndPathPoint extends BaseEntity implements IAnimate {
    anim: AnimatedSprite;
    position: Point;
    phase = 0;
    rectangle = new Graphics();
    halfCircle = new Graphics();

    constructor(cell?: Point) {
        super();
        this.position = cell;
    }

    async init() {
        let frames = [];
        for (let i = 0; i <= 9; i++) {
            frames.push(Texture.from(`smokeFx5-${i}.png`));
        }
        this.anim = new AnimatedSprite(frames);

        this.anim.scale = 1;
        this.anim.anchor.set(0.5);
        this.anim.animationSpeed = 0.2;
        this.anim.loop = false;
        this.anim.tint = "#a8c5ec";
        this.anim.y += 10;
        this.anim.gotoAndStop(frames.length-1);

        this.container.addChild(this.rectangle);
        this.container.addChild(this.halfCircle);
        this.container.addChild(this.anim);
    }

    play(point: Point) {
        const worldPoint = Di.get(World).container.toLocal(point);
        this.setPosition(worldPoint);
        this.anim.gotoAndPlay(0);
    }

    setPosition(point?: Point) {
        const cell = MathUtility.getCellFromPoint(point);
        this.position = MathUtility.getPointFromCell(cell).add(WorldConfig.centerCell);
        this.container.position = this.position
    }

    clear(): void {
        this.halfCircle.clear();
        this.rectangle.clear();
    }

    draw(): void {
        this.halfCircle
            .arc(0, 0, 20, 0, Math.PI)
            .fill({color: 0xff0000});

        this.rectangle
            .roundRect(-10, -10, 20, 20, 50)
            .stroke({width: 2, color: 0xffffff});
        this.rectangle.mask = this.halfCircle;
        this.rectangle.alpha = 0.5;
    }

    resize(): void {
    }

    update(time: Ticker): void {
        this.phase += time.deltaTime / 6;
        this.phase %= Math.PI * 2;

        this.halfCircle.rotation = this.phase;
    }

}
