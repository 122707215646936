import {AnimatedSprite, Container, Rectangle, Texture, Ticker} from "pixi.js";
import {Container as Di} from "typedi";
import {Point} from "@pixi/math";
import {WorldConfig} from "./config/WorldConfig";
import {IAnimate} from "./interface/IAnimate";
import {World} from "./World";
import {BaseEntity} from "./BaseEntity";
import {sound} from "@pixi/sound";
import {Player} from "./Player";

export class Coin extends BaseEntity implements IAnimate {

    anim: AnimatedSprite;
    container = new Container();
    position: Point;

    enable = true;

    constructor(cell: Point) {
        super();
        this.position = cell;
    }

    async init() {
        let frames = [];
        for (let i = 0; i < 9; i++) {
            frames.push(Texture.from(`coin-${i}.png`));
        }
        this.anim = new AnimatedSprite(frames);
        this.container.addChild(this.anim);

        this.anim.scale = 1;
        this.anim.anchor.set(0.5);
        this.anim.animationSpeed = 0.2;
        this.anim.interactive = true;
        this.anim.hitArea = new Rectangle(0, 0, 0, 0);

        this.anim.play();

        this.container.position = this.position.add(new Point(WorldConfig.halfCellSize, WorldConfig.halfCellSize));
    }

    draw() {
    }

    resize() {
    }

    clear() {
    }

    update(time: Ticker): void {
        if (!this.enable) {
            return;
        }
        const playerCell = Di.get(World).getPlayerCell();
        const coinCell = Di.get(World).getCoinCell(this);
        const v = playerCell.subtract(coinCell);
        if (v.magnitude() == 0) {
            this.enable = false;
            Di.get(World).container.removeChild(this.container);
            sound.play("coin");
            Di.get(Player).addCoin();
        }
    }
}
