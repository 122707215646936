import "es6-shim";
import "reflect-metadata";
import {Container as Di} from "typedi";
import {Game} from "./Game";

window.onload = async () => {
    const game = Di.get(Game);
    await game.init();
};

//TODO game_over font https://www.dafont.com/optimusprinceps.font (you died)
