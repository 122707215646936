import '@pixi/math-extras';
import {Point} from '@pixi/math';
import {Container as Di, Service} from "typedi";
import {Container, Graphics, Text, TextStyle, Ticker} from "pixi.js";
import {IAnimate} from "./interface/IAnimate";
import {Player} from "./Player";
import {Wall} from "./Wall";
import {Light} from "./Light";
import {Enemy} from "./Enemy";
import {BaseEntity} from "./BaseEntity";
import {MathUtility} from "./helper/MathUtility";
import {WorldConfig} from "./config/WorldConfig";
import {Character} from "./Character";
import {Coin} from "./Coin";

@Service()
export class World extends BaseEntity implements IAnimate {
    private lights: Light[] = [];
    grid = new Graphics();
    debug = new Graphics();
    cameraSpeed = 3;
    cameraPlay = false;
    textContainer = new Container();

    wallsMap: number[][];

    enemies: Enemy[] = [];

    walls: Wall[] = [];

    coins: Coin[] = [];

    constructor() {
        super();
    }

    async init() {
        await super.init();

        this.container.addChild(this.grid);
        this.container.addChild(this.debug);
        this.debug.alpha = 0.5;

        Di.get(Player).eventEmitter.on("change-position", (pos) => {
            this.cameraPlay = true;
        });


        await Di.get(Player).init();
        this.container.addChild(Di.get(Player).container);
    }

    async loadLevel(level: number) {
        this.clearWalls();
        this.generateWalls(level);

        this.clearCoins();
        await this.generateCoins(level);

        const emptyCellIndex = this.wallsMap[1].findIndex(cell => cell == 0);
        Di.get(Player).drawPlayer(new Point(emptyCellIndex, 1));
        Di.get(Player).healthPoints = 100;
        Di.get(Player).drawHealth();
        Di.get(Player).coins = 0;

        this.clearEnemies();
        await this.spawnEnemies(level);

        this.container.x = this.wrRect.width / 2;
        this.container.y = this.wrRect.height / 2;
    }

    clearEnemies() {
        for (let enemy of this.enemies) {
            this.container.removeChild(enemy.container);
            this.container.removeChild(enemy.light.container);
            const index = this.lights.indexOf(enemy.light);
            if(index!=-1){
                this.lights.splice(index,1);
            }
        }
        this.enemies = [];
    }

    clearWalls() {
        for (let wall of this.walls) {
            this.container.removeChild(wall.container);
        }
        this.walls = [];
    }

    clearCoins() {
        for (let coin of this.coins) {
            this.container.removeChild(coin.container);
        }
        this.coins = [];
    }

    async spawnEnemies(count: number) {
        for (let i = 1; i <= count; i++) {
            let x: number = 0;
            let y: number = 0;
            if (this.wallsMap)
                while (this.wallsMap[x][y]) {
                    x = MathUtility.getRandomInt(0, 5);
                    y = MathUtility.getRandomInt(10, 20);
                }

            const enemy = new Enemy(MathUtility.getPointFromCell(new Point(x, y)));
            this.wallsMap[x][y] = 1;
            this.enemies.push(enemy);
        }

        for (let enemy of this.enemies) {
            this.container.addChild(enemy.container);
            this.container.setChildIndex(enemy.container, 0);
            await enemy.init();
        }
    }

    async generateCoins(level: number) {
        const offset = Math.floor((this.wallsMap[0].length - 2) / 2);
        this.wallsMap.forEach((arr, i) => {
            arr.forEach((el, j) => {
                if (el != 0) {
                    return;
                }
                const r = MathUtility.getRandomInt(1, 30);
                if ((r % 17) == 0 && i % 2 == 0) {
                    const coin = new Coin(MathUtility.getPointFromCell(new Point(j - offset, i)));
                    this.coins.push(coin);
                }
            })
        });
        await this.drawCoins();
    }

    generateWalls(level: number) {
        const inc = level % 2 == 0 ? level : level - 1;
        const width = 20 + inc;
        const height = 30 + inc;
        this.wallsMap = MathUtility.getMatrix(width, height);

        for (let i = 0; i <= height; i++) {
            for (let j = 0; j <= width; j++) {
                if (i == 0 || j == 0) {
                    this.wallsMap[i][j] = 1;
                }
                if (i == height || j == width) {
                    this.wallsMap[i][j] = 1;
                }

                if (i == 1) {
                    continue;
                }
                const r = MathUtility.getRandomInt(0, height);
                if (r % 30 - level == 0) {
                    this.wallsMap[i][j] = 1;
                }
            }
        }
        // this.wallsMap[0][2] = 0;
        // this.wallsMap[0][3] = 0;

        this.wallsMap.forEach((arr, i) => {
            arr.forEach((el, j) => {
                if (el != 1) {
                    return;
                }
                const wall = new Wall(MathUtility.getPointFromCell(new Point(j - (width - 2) / 2, i)));
                this.walls.push(wall);
            });
        })
        this.walls.forEach((wall) => {
            this.container.addChild(wall.container);
            this.container.setChildIndex(wall.container, 0);
        });
    }

    clear() {
        this.grid.clear();
    }

    async draw() {
        // this.drawGrid();
        // this.drawWalls();
        this.drawEnemies();
        await this.drawCoins();
    }

    resize() {
        //TODO rework it method
    }

    update(time: Ticker) {
        this.focusToPlayer(time.deltaTime);
        this.enemies.forEach((enemy) => {
            enemy.update(time);
        });
        this.walls.forEach((wall) => {
            wall.update(time);
        });
        this.lights.forEach((light) => {
            light.update(time);
        });
        this.coins.forEach((coin) => {
            coin.update(time);
        })
    }

    private focusToPlayer(dt: number) {
        if (!this.cameraPlay) {
            return;
        }
        const plPos = Di.get(Player).container.position;
        const point = new Point(this.wrRect.width / 2, this.wrRect.height / 2).subtract(plPos);
        const target = point.subtract(this.container.position);
        const len = target.magnitude();
        if (len > this.cameraSpeed) {
            this.container.x += ~~(this.cameraSpeed * target.x / len);
            this.container.y += ~~(this.cameraSpeed * target.y / len);
            return;
        }
        this.cameraPlay = false;
    }

    drawGrid() {
        this.grid.clear();
        const cellsW = 1000 / 40;
        const cellsH = 1000 / 40;

        for (let i = 0; i <= cellsH; i++) {
            this.grid.moveTo(0, i * 40);
            this.grid.lineTo(1000, i * 40);
        }

        for (let i = 0; i <= cellsW; i++) {
            this.grid.moveTo(i * 40, 0);
            this.grid.lineTo(i * 40, 1000);
        }

        this.grid.stroke({width: 1, color: 0xffffff});

        this.grid.circle(0, 0, 5);
        this.grid.fill("#ddd");
        this.grid.alpha = 0.1;
        this.grid.pivot.x = ~~(this.grid.width / 2 - 20);
        this.grid.pivot.y = ~~(this.grid.height / 2 - 20);

        this.container.removeChild(this.textContainer);
        this.textContainer.destroy();
        this.textContainer = new Container();

        for (let i = -10; i <= 10; i++) {
            for (let j = -10; j <= 10; j++) {
                const text = new Text({
                    text: `(${i},${j})`,
                    style: {
                        fontSize: 12,
                        fontFamily: 'Comic Sans MS'
                    }
                });
                text.pivot = 0.5;
                text.position = new Point(i * WorldConfig.cellSize, j * WorldConfig.cellSize);
                text.alpha = 0.5;
                this.textContainer.addChild(text);
            }
        }

        this.container.addChild(this.textContainer);
    }

    drawWalls() {
        this.walls.forEach((wall) => {
            this.container.setChildIndex(wall.container, 1);

            if (Di.get(Player).isVisible(wall)) {
                wall.draw();
                wall.setVisible();
                return;
            }

            wall.visible = false;
        });
    }

    drawEnemies() {
        this.enemies.forEach((enemy) => {
            enemy.draw();
            enemy.resize();
        });
    }

    async drawCoins() {
        for (let coin of this.coins) {
            if (this.container.children.indexOf(coin.container) != -1) {
                continue;
            }
            this.container.addChild(coin.container);
            await coin.init();
        }
    }

    addLight(light: Light) {
        this.container.addChild(light.container);
        this.lights.push(light);
        this.lights.forEach((l) => {
            // this.container.setChildIndex(l.container, this.container.children.length-1);
            this.container.setChildIndex(l.container, 0);
        });
    }

    getEntityPosition(entity: BaseEntity) {
        return entity.container.position;
    }

    getCharacterCell(character: Character) {
        return MathUtility
            .getCellFromPoint(this.getEntityPosition(character));
    }

    getCoinCell(coin: Coin) {
        return MathUtility
            .getCellFromPoint(this.getEntityPosition(coin));
    }

    getPlayerCell() {
        const playerPosition = this.getEntityPosition(Di.get(Player));
        return MathUtility
            .getCellFromPoint(playerPosition);
    }

    getWallCell(wall: Wall) {
        return MathUtility.getCellFromPoint(wall.container.position);
    }
}
