import {Point} from "@pixi/math";
import {EventEmitter, Graphics, Ticker} from "pixi.js";
import {Container as Di} from "typedi";
import {AStarFinder, Grid} from "pathfinding";
import {World} from "./World";
import {IAnimate} from "./interface/IAnimate";
import {BaseEntity} from "./BaseEntity";
import {MathUtility} from "./helper/MathUtility";
import {WorldConfig} from "./config/WorldConfig";

enum Detected {
    Empty,
    Wall,
    Self,
    Player,
    Enemy
}

export abstract class Character extends BaseEntity implements IAnimate {
    id = Math.random().toString(16).slice(2);
    debug = new Graphics();
    grid = new Graphics();

    drawGrid() {
        for (let i = -Math.ceil(this.gridSize / 2); i < Math.ceil(this.gridSize / 2); i++) {
            this.grid.moveTo(i * 40 + 20, -20 * this.gridSize);
            this.grid.lineTo(i * 40 + 20, 20 * this.gridSize);

            this.grid.moveTo(-20 * this.gridSize, i * 40 + 20);
            this.grid.lineTo(20 * this.gridSize, i * 40 + 20);
        }
        this.grid.stroke({width: 1, color: "#d73434"});
        this.grid.alpha = 0.8;
    }

    gridSize = 11;
    halfGridSize = Math.ceil(this.gridSize / 2);
    startPos = new Point(Math.floor(this.gridSize / 2), Math.floor(this.gridSize / 2));

    pointOfInterest: Point;

    matrix: number[][];

    gridMatrix: Grid;

    finder = new AStarFinder();

    wallPoints: Point[] = [];

    path: Point[];

    movement = false;
    startMovement = false;

    eventEmitter = new EventEmitter<
        "change-position"
        | "end-movement"
        | "have-move-path"
        | "detect-obstacles"
        | "before-next-position"
        | "take-damage"
    >();

    callback: () => void | undefined;

    speed = 5;

    targetCell: Point;

    direction: Point;

    protected constructor() {
        super();
        this.container.addChild(this.grid);
    }

    async init(): Promise<void> {
        await super.init();
        this.matrix = MathUtility.getMatrix(this.gridSize, this.gridSize);

        this.eventEmitter.on("end-movement", (lastPos?: Point) => {
            this.movement = false;
            this.pointOfInterest = null;
            if (lastPos)
                this.setPosition(lastPos);
            // this.eventEmitter.emit("change-position", this.container.position);
        });
        this.eventEmitter.on("detect-obstacles", (globalCell: Point, lastPos: Point) => {
        });

        this.eventEmitter.on("have-move-path", () => {
            this.movement = true;
            // this.drawPath();
        })

        this.eventEmitter.on("change-position", (obj: { cell: Point, pos: Point, nextGlobalCell: Point }) => {
            this.setPosition(obj.pos);
            if (!obj.nextGlobalCell) {
                return;
            }

            const nextCell = this.toLocalCell(obj.nextGlobalCell);
            if (this.matrix[nextCell.y][nextCell.x] == 0) {
                return;
            }
            this.detectObstacle();
            if (this.matrix[nextCell.y][nextCell.x]) {
                this.movement = false;
            }
        });

        this.eventEmitter.on("before-next-position", (cell: Point) => {
            this.calculateDirection(cell);
            // this.drawPath();
        });
    }

    getAnotherCharactersCells(): Point[] {
        return Di.get(World).enemies.map(enemy => {
            const cell = this.toLocalCell(Di.get(World).getCharacterCell(enemy));
            if (enemy.id == this.id) {
                return null;
            }

            if (!this.isCellInMatrix(cell)) {
                return null;
            }

            return cell;
        }).filter(n => n != null);
    }

    getWallsCells() {
        return Di.get(World).walls.map(w => {
            const cell = this.toLocalCell(Di.get(World).getWallCell(w));

            if (!this.isCellInMatrix(cell)) {
                return null;
            }

            return cell;
        }).filter(n => n != null);
    }

    detectObstacle() {
        this.debug.clear();
        this.wallPoints = [];
        this.matrix = MathUtility.clearMatrix(this.matrix);
        const walls = this.getWallsCells();
        const enemies = this.getAnotherCharactersCells();

        enemies.forEach((cell, index) => {
            this.matrix[cell.y][cell.x] = Detected.Enemy;
            this.wallPoints.push(cell);
        });

        walls.forEach((cell, index) => {
            this.matrix[cell.y][cell.x] = Detected.Wall;
            this.wallPoints.push(cell);
        });

        const playerCell = this.getPlayerLocalCell();

        if (playerCell) {
            this.matrix[playerCell.y][playerCell.x] = Detected.Player;
        }

        this.matrix[this.startPos.y][this.startPos.x] = Detected.Self;
        this.debug.fill("#ea2020");
    }

    toLocalCell(globalCell: Point) {
        const myCell = Di.get(World).getCharacterCell(this);
        return new Point(globalCell.x - myCell.x, globalCell.y - myCell.y)
            .add(new Point(this.halfGridSize - 1, this.halfGridSize - 1));
    }

    toGlobalCell(localCell: Point) {
        const myCell = Di.get(World).getCharacterCell(this);
        return new Point(localCell.x + myCell.x, localCell.y + myCell.y)
            .subtract(new Point(this.halfGridSize - 1, this.halfGridSize - 1));
    }

    getPlayerLocalCell(): Point | null {
        const playerCell = Di.get(World).getPlayerCell();

        const localMatrixCell = this.toLocalCell(playerCell);

        if (this.isCellInMatrix(localMatrixCell)) {
            return localMatrixCell;
        }
        return null;
    }

    isCellInMatrix(cell: Point) {
        if (cell.x < 0 || cell.x > this.gridSize) {
            return false;
        }
        if (cell.y < 0 || cell.y > this.gridSize) {
            return false;
        }
        return true;
    }

    calculatePath(_cell?: Point) {
        this.path = [];
        const mousePos = this.pointOfInterest ? new Point().copyFrom(this.pointOfInterest) : null;

        this.targetCell = _cell ?? MathUtility
            .getCellFromPoint(mousePos, WorldConfig.halfCellSize)
            .add(new Point(this.halfGridSize, this.halfGridSize));

        this.gridMatrix = new Grid(this.matrix);

        const arr = this.finder.findPath(this.startPos.x, this.startPos.y, this.targetCell.x, this.targetCell.y, this.gridMatrix);
        arr.shift();

        this.path = arr.map(p => this.toGlobalCell(new Point(p[0], p[1])));
        this.eventEmitter.emit("have-move-path");
    }

    drawPath() {
        Di.get(World).debug.clear();
        if (!this.path) {
            return;
        }
        this.path.forEach((cell) => {
            const point = MathUtility.getPointFromCell(cell).add(WorldConfig.centerCell);
            Di.get(World).debug.circle(point.x, point.y, 5);
            Di.get(World).debug.fill("#206fcb");
        });
        Di.get(World).container.setChildIndex(Di.get(World).debug, 1);
    }

    isFreeCell(point: Point) {
        this.detectObstacle();
        const localCell = MathUtility
            .getCellFromPoint(point)
            .add(new Point(this.halfGridSize, this.halfGridSize));
        return this.matrix[localCell.x][localCell.y] === 0;
    }

    setPosition(pos: Point) {
        this.container.position = pos;
    }

    abstract updateAnimations(): void;

    calculateDirection(targetCell: Point) {
        const currentCell = Di.get(World).getCharacterCell(this);
        let newDir = targetCell.subtract(currentCell);

        newDir = new Point(Math.sign(newDir.x), Math.sign(newDir.y));

        if (!this.direction) {
            this.direction = newDir;
            this.updateAnimations();
            return;
        }

        if (this.direction.x == newDir.x && this.direction.y == newDir.y) {
            return;
        }
        this.direction = newDir;
        this.updateAnimations();
    }

    update(time: Ticker): void {
        if (this.movement && this.path.length) {

            const newCell = this.path[0];
            const newPos = MathUtility.getPointFromCell(newCell);

            if (!this.startMovement) {
                this.eventEmitter.emit("before-next-position", newCell);
            }

            const target = newPos.subtract(this.container.position);
            const len = target.magnitude();

            if (len > this.speed) {
                this.startMovement = true;
                const x = this.container.x + Math.floor(target.x / len * this.speed);
                const y = this.container.y + Math.floor(target.y / len * this.speed);
                this.setPosition(new Point(x, y));
                return;
            }

            this.startMovement = false;

            this.eventEmitter.emit("change-position", {
                cell: this.path.shift(),
                pos: newPos.clone(),
                nextGlobalCell: this.path[0]
            });

            if (this.path.length == 0) {
                this.eventEmitter.emit("end-movement", newPos);
            }
        }
    }
}
