import * as tf from '@tensorflow/tfjs';
import {Container as Di, Service} from "typedi";
import {AnimatedSprite, Application, Assets, Graphics, Rectangle, Text, TextStyle, Texture} from "pixi.js";
import {sound} from '@pixi/sound';
import {ApplicationOptions} from "pixi.js/lib/app/Application";
import {World} from "./World";
import {Player} from "./Player";
import {UI} from "./UI";
import {Point} from "@pixi/math";

@Service()
export class Game {
    app: Application = new Application();
    wrapper = document.getElementById('wrapper');
    wrRect = this.wrapper.getBoundingClientRect();

    stopMusicBtn = new Graphics();
    enableMusic = true;

    scenes = {
        worldScene: Di.get(World)
    }

    animatedComponents = [
        Di.get(World),
        Di.get(Player)
    ];

    ui = new UI();

    gameOver = false;
    dieG = new Graphics();
    textStyle = new TextStyle({
        fontFamily: 'OptimusPrinceps',
        fontSize: 40,
        fontWeight: 'bold',
        fill: "#f50000",
    });
    dieText = new Text({
        text: "YOU DIED",
        style: this.textStyle,
    });

    level = 1;

    async init() {
        await this.app.init({
            background: '#1c1e25',
            resizeTo: this.wrapper,
            antialias: true,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
            preference: 'webgl',
        } as Partial<ApplicationOptions>);
        this.app.stage.eventMode = 'static';
        this.app.stage.hitArea = this.app.screen;
        this.wrapper.appendChild(this.app.canvas);

        sound.add('music', './assets/music.mp3');
        sound.add('footsteps', './assets/footsteps.mp3');
        sound.add('coin', './assets/coin.mp3');
        sound.add('success', './assets/success.mp3');
        sound.add('playerHit', './assets/player-hit.mp3');
        sound.add('gameOver', './assets/game_over.mp3');

        await Assets.load("./assets/OptimusPrinceps.ttf");

        await Assets.load("./assets/player/Idle/idle.json");
        await Assets.load("./assets/player/Walk/walk_down.json");
        await Assets.load("./assets/player/Walk/walk_up.json");
        await Assets.load("./assets/player/Walk/walk_left_down.json");

        await Assets.load("./assets/coin.json");
        await Assets.load("./assets/smokeFx5.json");
        await Assets.load("./assets/enemy/orc_idle.json");
        await Assets.load("./assets/enemy/orc_walk.json");
        await Assets.load("./assets/enemy/orc_attack.json");

        this.app.stage.addChild(this.stopMusicBtn);
        this.stopMusicBtn.interactive = true;
        this.stopMusicBtn.eventMode = 'static';
        this.stopMusicBtn.hitArea = new Rectangle(0, 0, 40, 40);
        this.stopMusicBtn.cursor = "pointer";
        this.stopMusicBtn.y = 40;
        this.stopMusicBtn.on("pointerdown", () => {
            this.enableMusic = !this.enableMusic;
            this.drawMenu();
            if (this.enableMusic) {
                sound.play("music", {loop: true, volume: 0.5});
                return;
            }
            sound.stop("music");
        });
        this.drawMenu();

        //@ts-ignore for devtools
        globalThis.__PIXI_APP__ = this.app;

        await this.scenes.worldScene.init();
        await Di.get(World).loadLevel(this.level);

        this.app.stage.addChild(this.scenes.worldScene.container);

        this.app.stage.setChildIndex(this.stopMusicBtn, this.app.stage.children.length - 1);

        await this.ui.init();
        this.app.stage.addChild(this.ui.container);
        this.ui.container.position = new Point(this.wrRect.width - this.ui.container.width - 10, 10);

        const meter = new FPSMeter();

        sound.play("music", {loop: true, volume: 0.5});

        this.app.ticker.add((time) => {
            meter.tick();
            if (this.gameOver) {
                if (this.dieText.style.fontSize > 100) {
                    return;
                }
                this.dieText.style.fontSize += 0.2;
                return;
            }

            for (let com of this.animatedComponents) {
                com.update(time);
            }
        });
    }

    drawMenu() {
        this.stopMusicBtn.clear();
        this.stopMusicBtn.rect(10, 10, 40, 40);
        if (this.enableMusic) {
            this.stopMusicBtn.rect(20, 20, 20, 20);
            this.stopMusicBtn.stroke({width: 1, color: "#21faef"});
            return;
        }

        this.stopMusicBtn.moveTo(20, 20);
        this.stopMusicBtn.lineTo(40, 30);
        this.stopMusicBtn.lineTo(20, 40);
        this.stopMusicBtn.lineTo(20, 20);

        this.stopMusicBtn.stroke({width: 1, color: "#21faef"});
    }

    async tfExample() {
        // Define a model for linear regression.
        const model = tf.sequential();
        model.add(tf.layers.dense({units: 1, inputShape: [1]}));

        model.compile({loss: 'meanSquaredError', optimizer: 'sgd'});

        // Generate some synthetic data for training.
        const xs = tf.tensor2d([-1, 0, 1, 2, 3, 4, 5, 6], [8, 1]);
        const ys = tf.tensor2d([-3, -1, 1, 3, 5, 7, 9, 11], [8, 1]);

        await model.fit(xs, ys, {epochs: 250});
        //@ts-ignore
        // console.log(model.predict(tf.tensor2d([20], [1, 1])).dataSync());
    }

    doGameOver() {
        this.gameOver = true;
        Di.get(Game).app.stage.addChild(this.dieG);
        Di.get(Game).app.stage.addChild(this.dieText);
        this.dieText.anchor = 0.5;

        this.dieText.position.x = this.wrRect.width / 2;
        this.dieText.position.y = this.wrRect.height / 2;

        this.dieG.rect(0, 0, this.wrRect.width, this.wrRect.height);
        this.dieG.fill("#000");
        this.dieG.alpha = 0.9;
        sound.play("gameOver");

        setTimeout(async () => {
            await this.nextLevel();
        }, 8000);
    }

    async nextLevel() {
        if (!this.gameOver)
            this.level++;
        sound.stop("gameOver");
        this.app.stage.removeChild(this.dieText);
        this.app.stage.removeChild(this.dieG);

        await Di.get(World).loadLevel(this.level);
        this.ui.setText();
        this.gameOver = false;
    }
}
