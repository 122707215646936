import {Character} from "./Character";
import {IAnimate} from "./interface/IAnimate";
import {AnimatedSprite, Graphics, Texture, Ticker} from "pixi.js";
import {Point} from "@pixi/math";
import {Container as Di} from "typedi";
import {Light} from "./Light";
import {DropShadowFilter} from "pixi-filters";
import {WorldConfig} from "./config/WorldConfig";
import {Hunting} from "./Hunting";
import {World} from "./World";
import {Player} from "./Player";

export class Enemy extends Character implements IAnimate {
    body = new Graphics();
    shadow = new Graphics();
    lightBounds = WorldConfig.innerCircleRect;

    gridSize = 11;
    light: Light;
    path: Point[];
    speed = 1;

    callback: any;

    ai = new Hunting();

    anim: AnimatedSprite;

    animations = {
        idle: {
            up: "orc_idle_up-",
            left: "orc_idle_left-",
            down: "orc_idle_down-",
            right: "orc_idle_right-",
        },
        walk: {
            up: "orc_walk_up-",
            left: "orc_walk_left-",
            down: "orc_walk_down-",
            right: "orc_walk_right-",
        },
        attack: {
            up: "orc_attack_up-",
            left: "orc_attack_left-",
            down: "orc_attack_down-",
            right: "orc_attack_right-",
        }
    }

    currentAnimation = this.animations.idle.down;

    dirAnimIdle: any = {
        ["-1,0"]: this.animations.idle.left,
        ["1,0"]: this.animations.idle.right,
        ["0,1"]: this.animations.idle.down,
        ["0,-1"]: this.animations.idle.up,
    }

    dirAnimWalk: any = {
        ["-1,0"]: this.animations.walk.left,
        ["1,0"]: this.animations.walk.right,
        ["0,1"]: this.animations.walk.down,
        ["0,-1"]: this.animations.walk.up,
    }

    dirAnimAttack: any = {
        ["-1,0"]: this.animations.attack.left,
        ["1,0"]: this.animations.attack.right,
        ["0,1"]: this.animations.attack.down,
        ["0,-1"]: this.animations.attack.up,
    }

    lenToPlayer: number;

    attackTiming = 20;
    attackTimingCounter = 0;

    constructor(pos: Point) {
        super();
        // this.container.addChild(this.shadow);
        // this.container.addChild(this.body);
        // this.container.addChild(this.debug);
        this.container.position = pos;
        this.container.pivot.x = -WorldConfig.halfCellSize;
        this.container.pivot.y = -WorldConfig.halfCellSize;
        // this.container.pivot.x = -10;
        // this.container.pivot.y = -10;
        // this.container.x -= WorldConfig.halfCellSize;
        // this.container.y -= WorldConfig.halfCellSize;

        // this.body.alpha = 0.5;
        this.ai.setParent(this);
    }

    setPosition(pos: Point) {
        // super.setPosition(pos);
        this.container.position = new Point(pos.x, pos.y);

    }

    async init() {
        await super.init();
        this.light = new Light(new Point(0, 0));
        await this.light.init();
        this.light.setParent(this);
        this.light.light.scale = 0.5;
        this.light.light.tint = Math.random() * 0xffffff;

        let frames = [];
        for (let i = 0; i < 4; i++) {
            frames.push(Texture.from(`${this.currentAnimation}${i}.png`));
        }
        this.anim = new AnimatedSprite(frames);
        this.anim.scale = 1.8;
        this.anim.anchor.set(0.5);
        this.anim.animationSpeed = 0.2;
        this.anim.play();
        this.container.addChild(this.anim);

        this.eventEmitter.on("end-movement", () => {
            this.calculateLanToPlayer();
            this.movement = false;
            this.updateAnimations();
            if (this.callback) {
                this.callback();
                this.callback = undefined;
            }
        });

        this.eventEmitter.on("change-position", () => {
            // this.drawPath();
        });

        this.eventEmitter.on("before-next-position", () => {
            // this.updateAnimations();
        });
    }

    calculateLanToPlayer() {
        const currentPos = Di.get(World).getCharacterCell(this);
        const playerPos = Di.get(World).getCharacterCell(Di.get(Player));

        this.calculateDirection(playerPos);

        this.lenToPlayer = playerPos.subtract(currentPos).magnitude();
    }

    updateAnimations() {
        this.currentAnimation = this.dirAnimIdle[`${this.direction.x},${this.direction.y}`];

        if (this.movement) {
            this.currentAnimation = this.dirAnimWalk[`${this.direction.x},${this.direction.y}`];
        }

        if (!this.movement && this.lenToPlayer == 1) {
            if (Di.get(Player).healthPoints >= 0) {
                this.currentAnimation = this.dirAnimAttack[`${this.direction.x},${this.direction.y}`];
            }
        }

        if (!this.currentAnimation) {
            //TODO 1,1 dir
            return;
        }

        const countFrames = this.movement ? 8 : 4;

        const frames = [];
        for (let i = 0; i < countFrames; i++) {
            frames.push(Texture.from(`${this.currentAnimation}${i}.png`));
        }

        this.anim.textures = frames;
        this.anim.play();
    }

    move(cell: Point) {
        this.detectObstacle();
        this.calculatePath(cell);
    }

    update(time: Ticker): void {
        super.update(time);
        if (this.light)
            this.light.setPosition(this.container.position);
        this.ai.update(time);

        if (!this.movement && this.lenToPlayer == 1) {

            this.attackTimingCounter++;
            if (this.attackTimingCounter >= this.attackTiming) {
                this.attackTimingCounter = 0;
                Di.get(Player).eventEmitter.emit("take-damage");
            }
        }
    }

    draw() {
        // this.drawGrid();
        this.drawEnemy();
    }

    clear() {
        this.body.clear();
    }

    resize() {
    }

    drawEnemy() {
        this.body.circle(0, 0, 20);
        this.body.fill("#e50c0c");
        // this.body.stroke("#e50c0c");
        this.shadow.circle(0, 0, 20);
        this.shadow.fill("#c437d0");
        this.shadow.filters = [new DropShadowFilter({shadowOnly: true})];
    }
}
