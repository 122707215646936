import {Point} from "@pixi/math";
import {Segment} from "./Segment";

export class EndPoint extends Point {
    beginsSegment: boolean;
    segment: Segment;
    angle: number;

    constructor(x: number, y: number, beginsSegment?: boolean, segment?: Segment, angle?: number) {
        super(x,y);
        this.beginsSegment = beginsSegment;
        this.segment = segment;
        this.angle = angle;
    }
}
