import {Graphics, TextStyle, Text} from "pixi.js";
import {Container as Di} from "typedi";
import {BaseEntity} from "./BaseEntity";
import {Player} from "./Player";
import {World} from "./World";
import {sound} from "@pixi/sound";
import {Game} from "./Game";

export class UI extends BaseEntity {
    header: Text;
    subHeader: Text;
    style = new TextStyle({
        fontFamily: 'OptimusPrinceps',
        fontSize: 20,
        fontWeight: 'bold',
        fill: "#363d46",
    });
    graphics = new Graphics();

    async init(): Promise<void> {
        await super.init();

        this.header = new Text({
            text: "level:",
            style: this.style,
        });
        this.subHeader = new Text({
            text: "coins:",
            style: this.style,
        });

        this.container.addChild(this.graphics);
        this.container.addChild(this.header);
        this.container.addChild(this.subHeader);

        this.header.y = 10;
        this.header.x = 10;

        this.subHeader.y = 30;
        this.subHeader.x = 10;

        this.drawBg();
        this.setText();
    }

    drawBg() {
        this.graphics.clear();
        this.graphics.roundRect(0, 0, 200, 60, 15);
        this.graphics.fill("#ddd");
    }

    setText() {
        const coins = Di.get(Player).coins;
        const allCoins = Di.get(World).coins.length;
        const level = Di.get(Game).level;
        this.header.text = `level: ${level}`;
        this.subHeader.text = `coins: ${coins}/${allCoins}`;
        if (coins == allCoins) {
            sound.play("success");
        }
    }

    clear(): void {
        this.graphics.clear();
    }

    draw(): void {
    }

    resize(): void {
    }

}
